import {
    createRouter,
    createWebHistory
} from 'vue-router';
import {
    i18n
} from '@/main';

import { supabase } from '../lib/supabaseClient'
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';
import store from '../store';

const routes = [

    // 메인홈
    {
        path: '/',
        name: 'tipsbee',
        component: () => import('../views/home/index'),
        meta: {
            title: "Home",
        },
    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login'),
        meta: {
            title: "Login",
        },
    },

    // 회원가입1
    {
        path: '/join1',
        name: 'join1',
        component: () => import('../views/account/join1'),
        meta: {
            title: "Agreement",
        },
    },

    // 회원가입2
    {
        path: '/join2',
        name: 'join2',
        component: () => import('../views/account/join2'),
        meta: {
            title: "Join",
        },
    },

    // 비밀번호 찾기
    {
        path: '/findPw',
        name: 'findPw',
        component: () => import('../views/account/findPw'),
        meta: {
            title: "Find Password",
        },
    },

    // 비밀번호 변경 - 미로그인 시
    {
        path: '/resetPw',
        name: 'resetPw',
        component: () => import('../views/account/resetPw'),
        meta: {
            title: "Reset Password",
        },
    },

    // company
    {
        path: '/company',
        name: 'company',
        component: () => import('../views/company/main'),
        meta: {
            title: "Company",
            layout: 'company',
        },
    },

    // 마이페이지
    {
        path: '/mypage',
        name: 'mypage',
        component: () => import('../views/mypage/list'),
        meta: {
            authRequired: true,
            title: "Mypage",
            layout: 'company',
        },
    },

    {
        path: '/changePW',
        name: 'changePW',
        component: () => import('../views/mypage/changePW'),
        meta: {
            authRequired: true,
            title: "Change Password",
            layout: 'company',
        },
    },

    // 마이페이지 - 회원탈퇴
    {
        path: '/secession',
        name: 'secession',
        component: () => import('../views/mypage/secession'),
        meta: {
            authRequired: true,
            title: "Cancel Membership",
            layout: 'company',
        },
    },

    // 공지사항
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/notice/list'),
        meta: {
            title: "Notice",
            layout: 'company',
        },
    },

    // 공지사항 상세
    {
        path: '/noticeDetail/:noticeid',
        name: 'noticeDetail',
        component: () => import('../views/notice/detail'),
        meta: {
            title: "Notice Detail",
            layout: 'company',
        },
    },

    // 이용약관
    {
        path: '/terms_use',
        name: 'terms_use',
        component: () => import('../views/company/terms_use'),
        meta: {
            title: "Terms of Use",
            layout: 'company',
        },
    },

    // 개인정보처리방침
    {
        path: '/privacy_policy',
        name: 'privacy_policy',
        component: () => import('../views/company/privacy_policy'),
        meta: {
            title: "Privacy Policy",
            layout: 'company',
        },
    },

    // 결제 성공 페이지
    {
        path: '/paymentSuccess',
        name: 'paymentSuccess',
        component: () => import('../views/payment/success'),
        meta: {
            title: "Payment Success",
            layout: 'company',
        },
    },

    // 결제 성공 페이지 - 테스트트
    {
        path: '/paymentSuccess1',
        name: 'paymentSuccess1',
        component: () => import('../views/payment/success1'),
        meta: {
            title: "Payment Success",
            layout: 'company',
        },
    },

    // 결제 실패 페이지
    {
        path: '/paymentFail',
        name: 'paymentFail',
        component: () => import('../views/payment/fail'),
        meta: {
            title: "Payment Fail",
            layout: 'company',
        },
    },

];

const router = createRouter({
    history: createWebHistory(),
    //linkActiveClass: "active",
    //linkExactActiveClass: 'sub-active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                left: 0,
                top: 0
            };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    // 페이지 이동 시 로컬 스토리지에 저장된 언어 값을 확인하여 적용
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale && savedLocale !== i18n.global.locale) {
        i18n.global.locale = savedLocale;
    }
    
    if(to.meta.title != undefined){
        document.title = "Tipsbee | " + to.meta.title;
    }else{
        document.title = "Tipsbee";
    }

    var token = VueCookies.get('tipsbeeweb_accessToken');
    var refreshToken = VueCookies.get('tipsbeeweb_refreshToken');
    var isVerified = false;

    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
        }
    }

    if (store.state.user.loggedIn){
        return next();
    }else{
        if (token !== null){
            // 토큰이 쿠키에 존재하는 경우

            if(isVerified){
                // accessToken 인증된 경우
                
                // Define an async function within the beforeEach hook
                async function checkSession() {
                    try {
                        const sessionInfo = await getSession();

                        if (sessionInfo.success) {
                            var userInfo = {
                                "accessToken": sessionInfo.accessToken,
                                "refreshToken": sessionInfo.refreshToken,
                                "useruid": VueCookies.get('tipsbeeweb_useruid'),
                                "email": VueCookies.get('tipsbeeweb_email'),
                                "phone": VueCookies.get('tipsbeeweb_phone'),
                                "username": VueCookies.get('tipsbeeweb_username'),
                                "usertype": VueCookies.get('tipsbeeweb_usertype'),
                                "userid": VueCookies.get('tipsbeeweb_userid'),
                            };

                            //console.log(userInfo);

                            store.commit('setLoggedIn', true);
                            store.commit('setUser', userInfo);

                            return true; // Indicate success
                        } else {
                            // 세션 불러오기 실패 시
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login");
                            return false; // Indicate failure
                        }
                    } catch (error) {
                        console.error("Error fetching session:", error);
                        next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        return false;
                    }
                    
                }
                // Call the async function and handle its result
                const success = await checkSession();
                if (success) {
                    return next();
                }
                
            }else{
                // accessToken 인증만료된 경우

                // refreshToken 있을 경우 재발급
                if(refreshToken){
                    async function checkRefreshSession() {
                        try {
                            const refreshSessionInfo = await refreshSession(refreshToken);

                            if (refreshSessionInfo.success) {
                                var userInfo = {
                                    "accessToken": refreshSessionInfo.accessToken,
                                    "refreshToken": refreshSessionInfo.refreshToken,
                                    "useruid": VueCookies.get('tipsbeeweb_useruid'),
                                    "email": VueCookies.get('tipsbeeweb_email'),
                                    "username": VueCookies.get('tipsbeeweb_username'),
                                    "usertype": VueCookies.get('tipsbeeweb_usertype'),
                                    "userid": VueCookies.get('tipsbeeweb_userid'),
                                };

                                //console.log(userInfo);

                                store.commit('setLoggedIn', true);
                                store.commit('setUser', userInfo);

                                return true; // Indicate success
                            } else {
                                // 세션 불러오기 실패 시
                                store.commit('logoutUser')
                                var { error } = supabase.auth.signOut()
                                next("/login");
                                return false; // Indicate failure
                            }
                        }catch (error) {
                            console.error("Error fetching session:", error);
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        }
                    }
                    const success = await checkRefreshSession();
                    if (success) {
                        return next();
                    }
                    
                }else{
                    store.commit('logoutUser')
                    var { error } = supabase.auth.signOut()
                    next("/login");
                }

            }
            
        }else{
            // 토큰이 쿠키에 없는 경우
            store.commit('logoutUser')
            const { error } = supabase.auth.signOut()

            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if(!authRequired){
                return next();
            }else{
                next("/login");
            }
        }
        
    }
});

// 세션 불러오기
async function getSession(){
    
    const { data, error } = await supabase.auth.getSession()
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}

// refresh token으로 세션 다시 생성하기
async function refreshSession(refreshToken){

    const { data, error } = await supabase.auth.refreshSession({
        refresh_token: refreshToken
    })
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}


export default router;