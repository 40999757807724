import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';
import PrimeVue from 'primevue/config';
import AnimateOnScroll from 'primevue/animateonscroll';
import Dialog from 'primevue/dialog';
import FocusTrap from 'primevue/focustrap';
import mitt from 'mitt';

import "primevue/resources/themes/lara-light-green/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';

import '@/asset/css/reset.css';
import '@/asset/css/style.css';
import '@/asset/css/fonts.css';
import '@/asset/css/swiper-bundle.min.css';

var lang = navigator.language;
var locale = "en";

if(lang.includes("en")){
    locale = "en";
}else if(lang.includes("ko")){
    locale = "ko";
}else if(lang.includes("cn") || lang.includes("CN")){
    locale = "cn";
}else if(lang.includes("es")){
    locale = "es";
}else if(lang.includes("vn") || lang.includes("VN")){
    locale = "vn";
}else if(lang.includes("it")){
    locale = "it";
}

export const i18n = createI18n({
    locale: locale, // 기본 언어 설정
    messages: {
        en: require('./locales/en.json'),
        ko: require('./locales/ko.json'), 
        cn: require('./locales/cn.json'),  
        es: require('./locales/es.json'),  
        it: require('./locales/it.json'),  
        vn: require('./locales/vn.json'),  
    }
});

const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(store).use(router).use(i18n).use(PrimeVue).directive('animateonscroll', AnimateOnScroll);
app.component('Dialog', Dialog);
app.directive('focustrap', FocusTrap);
app.mount('#app');

export { emitter };
