export default {
    //URL: 'https://localhost:7180',
    //URL: 'https://api-tipsbee.devaidsoft.net',
    URL: 'https://api.tipsbee.io',
    ROUTES: {
        register: '/',
    },
    HEADERS: {
        'AppID' : '5c88394d-72c7-468b-9c83-c3c6788a0951',
        'AppKey' : 'BJ43JKE-EB3MD2V-KJ1W7HJ-F250JME'
    },
}