<template>
    <div>
        <Dialog :visible="isModalOpen" class="BuyTicketsModal" modal :draggable="false" :style="{ width: '40vw' }"
            :breakpoints="{ '1024px': '60vw', '991px': '80vw' }">
            <!-- 모달 헤더 커스텀 -->
            <template #header>
                <div class="custom-header d-flex">
                <!-- 헤더 내용 -->
                <Button icon="pi pi-times" class="p-button-text" @click="handleModalClose" />
                </div>
            </template>
            <div class="modalCont">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel">
                            <div class="panel-body ">
                                <ul class="infotxt">
                                    <li>✔ {{ $t('팁스비 회원은 개인용과 기관용으로 나뉩니다') }}</li>
                                    <li>✔ {{ $t('연간 계약을 원하시는 학교,유치원,체험관 등 기관용 티켓을 구입하시는 분은 아래의 ​‘문의하기’ 버튼을 눌러서 필요하신 내용을 요청하시면 담당자가 연락을 드립니다') }}</li>
                                    <li>✔ {{ $t('팁스비가 설치된 하나의 컴퓨터에서 다수의 사용자가 사용 할 경우에는 기관용 티켓을 구매하셔야 합니다') }}</li>
                                    <li>✔ {{ $t('개인용 티켓은 1회성 입장권으로 프로그램 설치 후 사용을 하시면 프로그램 특성 상 환불이 불가합니다. 하지만, 구입 후 1개월 간 설치한 컴퓨터나 노트북에서 언제든 사용이 가능합니다') }}</li>
                                </ul>
                                <p>
                                    <span>※ {{ $t('주의: 팁스비 프로그램은 최초 1대의 컴퓨터에만 설치할 수 있습니다. 티켓 구입 전에 아래 주의사항을 반드시 확인해주세요') }}</span><br><br>
                                    1. {{ $t('운영체계(OS)') }}<br>
                                    2. {{ $t('웹캠이 설치된 컴퓨터 (노트북)') }}<br>
                                    3. {{ $t('CPU intel i3 10세대 이상') }}
                                </p>
                                <div class="memberType">
                                    <div v-if="!isLogin || (isLogin && usertype == 'individual')" class="flex align-items-center">
                                        <RadioButton v-model="memtype" inputId="individualtxt" name="pizza" value="individualtxt" />
                                        <label for="individualtxt" class="ml-2">{{ $t('개인용') }}<br>{{ $t('10,000원/월') }}</label>
                                    </div>
                                    <div v-if="!isLogin || (isLogin && usertype != 'individual')" class="flex align-items-center">
                                        <RadioButton v-model="memtype" inputId="organizationtxt" name="pizza" value="organizationtxt" />
                                        <label for="organizationtxt" class="ml-2">{{ $t('기관용') }}<br>{{ $t('100,000원/월') }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-bottom">
                <Button :label="$t('문의하기')" severity="info" @click="handleInquiryClick" />
                <Button :label="$t('구입하기')" severity="Primary" @click="checkLoginBeforePurchase();" />
            </div>
        </Dialog>

        <!-- tosspayment 모달창 -->
        <Dialog v-model:visible="paymodal" modal :draggable="false"  :style="{ width: '40vw' }"
            header="&nbsp;"
            class="BuyTicketsModal" :breakpoints="{ '1024px': '60vw', '991px': '80vw',}" >
            <template #header>
                <div class="custom-header d-flex">
                    <!-- 헤더 내용 -->
                    <Button icon="pi pi-times" class="p-button-text" @click="closepayModal" />
                </div>
            </template>

            <div id="payment-widget"></div>
            <div id="agreement"></div>

            <div class="payBox">
                <div>
                    <label for="customerEmail">이메일</label>
                    <input type="text" v-model="customerEmail" id="customerEmail" />
                </div>
                <div>
                    <label for="customerName">이름</label>
                    <input type="text" v-model="customerName" id="customerName" />
                </div>
                <div>
                    <label for="customerMobilePhone">휴대폰번호</label>
                    <input type="text" v-model="customerMobilePhone" id="customerMobilePhone" maxlength="11" 
                        @keyup="returnOnlyNumber" @change="returnOnlyNumber" />
                </div>
            </div>

            <div class="modal-bottom">
                <Button :label="$t('닫기')"  severity="secondary" @click="closepayModal"/>
                <Button id="payment-button" severity="Primary" @click="createOrderId">결제하기</Button>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import { ref, defineEmits } from 'vue';

const emits = defineEmits(['close']);

const handleModalClose = () => {
    emits('close');
};

const memtype = ref(""); 

const props = defineProps({
    isModalOpen: Boolean
});

</script>

<script>

import { supabase } from '../../lib/supabaseClient'
import { loadPaymentWidget, ANONYMOUS } from '@tosspayments/payment-widget-sdk'

export default {
    props: {
        isModalOpen: Boolean
    },
    data(){
        return {
            paymodal: false,
            isLogin: false,
            useruid: "",
            usertype: "",
            productId: 1,
            orderId: "",
            orderName: "",
            customerEmail: "",
            customerName: "",
            customerMobilePhone: "",
            clientKey: "test_ck_0Poxy1XQL8RlNN4b56kV7nO5Wmlg",
            paymentWidget: null,
            paymentMethodWidget: null,
            amount: 10000,
        }
    },
    mounted(){
        var user = this.$store.getters['user'];
        if(user.loggedIn){
            this.isLogin = true;
            this.useruid = user.data.useruid;
            this.usertype = user.data.usertype;
            if(this.usertype == "individual"){
                this.amount = 10000;
                this.orderName = "팁스비 개인용 티켓";
                this.productId = 1;
            }else{
                this.amount = 100000;
                this.orderName = "팁스비 기관용 티켓";
                this.productId = 2;
            }
        }else{
            this.isLogin = false;
        }
        this.emitter.on("checkLogin", this.getUserInfo);
    },
    beforeUnmount() {
        this.emitter.off("checkLogin", this.getUserInfo);
    },
    methods: {

        // 사용자 정보 다시 가져오기
        async getUserInfo(){
            var user = this.$store.getters['user'];
            if(user.loggedIn){
                this.isLogin = true;
                this.useruid = user.data.useruid;
                this.usertype = user.data.usertype;
                if(this.usertype == "individual"){
                    this.amount = 10000;
                    this.orderName = "팁스비 개인용 티켓";
                    this.productId = 1;
                }else{
                    this.amount = 100000;
                    this.orderName = "팁스비 기관용 티켓";
                    this.productId = 2;
                }
            }else{
                this.isLogin = false;
            }
        },

        // 티켓 구매 전 로그인 체크 하기
        checkLoginBeforePurchase(){
            if(this.isLogin){
                this.setPaymentModal();
            }else{
                // 미 로그인 시
                alert(this.$t('alerts.You can purchase a ticket atfer login'));
                return false;
            }
        },

        //  결제창 생성
        async setPaymentModal() {

            this.openpayModal(); // 결제창 모달 열기

            this.paymentWidget = await loadPaymentWidget(this.clientKey, this.useruid);
            this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods(
                "#payment-widget",
                { value: this.amount },
                { variantKey: "DEFAULT" }
            );

            this.paymentWidget.renderAgreement(
                "#agreement",
                { variantKey: "AGREEMENT" }
            );
        },

        // 주문번호 생성하기
        createOrderId(){
            if(this.customerEmail == ""){
                alert(this.$t('alerts.Enter your email'));
                return false;
            }
            if(this.customerName == ""){
                alert(this.$t('alerts.Enter your name'));
                return false;
            }
            if(this.customerMobilePhone == ""){
                alert(this.$t('alerts.Please enter your phone number'));
                return false;
            }

            var params = {
                userUid: this.useruid,
                amount: this.amount,
                productId: this.productId,
                orderName: this.orderName,
                customerEmail: this.customerEmail,
                customerName: this.customerName,
                customerPhone: this.customerMobilePhone,
            }

            //console.log(params);

            this.$store.dispatch('orderStore/create', params)
            .then((res) => {
                //console.log(res.data);
                if(res.data.success){
                    this.orderId = res.data.data.orderId;

                    this.handlePayment();
                }
            })
            .catch(({message}) => (alert(message)));
        },


        // 결제 진행하기
        handlePayment() {
            // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요. 
            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.  
            this.paymentWidget.requestPayment({
                orderId: this.orderId,
                orderName: this.orderName,
                successUrl: window.location.origin + "/paymentSuccess",
                failUrl: window.location.origin + "/paymentFail",
                customerEmail: this.customerEmail,
                customerName: this.customerName,
                customerMobilePhone: this.customerMobilePhone,
                useInternationalCardOnly :true,
            });
        },

        // 숫자만 입력
        returnOnlyNumber(event){
            event.target.value = event.target.value.replace(/[^\d]/g, '');
        },

        // Paypal 모달창 열기
        openpayModal() {
            // 모달이 열릴 때 body에 스타일 추가
            document.documentElement.style.overflow = 'hidden';
            this.paymodal = true;
        },

        // Paypal 모달창 닫기
        closepayModal() {
            this.paymodal = false;
            document.documentElement.style.overflow = ''; // 스크롤을 다시 보이게
        },

        // 문의하기 클릭(문의하기 영역으로 스크롤이동)
        scrollToSection() {
            document.getElementById('sec5').scrollIntoView({
                behavior: 'smooth'
            });
        },

        handleInquiryClick() {
            // 페이지별
            if (this.$route.path === '/join1' || this.$route.path === '/join2' || this.$route.path === '/login' || this.$route.path === '/findPw' || this.$route.path === '/findPw') {
                this.$router.push('/');
                setTimeout(() => this.scrollToSection(), 500); 
                this.$emit('close'); 
            } else {
                this.scrollToSection();
                this.$emit('close'); // 부모 컴포넌트로 'close' 이벤트 발생
            }
        },

    },
};
</script>

<style scoped>

</style>