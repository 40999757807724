import axios from "axios";
import SERVER from '@/API/url'

const senderStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 문의 메일 발송
        sendTemplateMail: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendTemplateMail', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // SMS 발송
        sendSMS: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Sender/SendSMS', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default senderStore