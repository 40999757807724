<!-- App.vue -->

<template>
  <div>
    <router-view></router-view>
    <!-- 모달 컴포넌트 -->
    <TicketPurchaseModal :isModalOpen="isModalOpen" @close="closeModal" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { emitter } from '@/main.js';
import TicketPurchaseModal from "./components/modal/TicketPurchaseModal.vue";

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true; // 모달 열기 이벤트 수신
  emitter.emit('checkLogin');
};

const closeModal = () => {
  isModalOpen.value = false; // 모달을 닫음
};

emitter.on('openModal', openModal);
</script>
